import React from 'react'
import Layout from "../components/layout"
import { Link } from "gatsby"
import Footer from '../components/footer'
import CognizantImage from '../images/solutions/cognizant.jpg'
import EcommerceImage from '../images/solutions/ecommerce.jpg'
import PayImage from '../images/solutions/pay.jpg'
import PulseImage from '../images/solutions/pulse.jpg'
import networking from '../images/services/networking.jpg'
import performance from '../images/services/performance.jpg'
import rationalizaion from '../images/services/rationalizaion.jpg'
import security from '../images/services/security.jpg'

import SmileyImage from '../images/solutions/smiley.jpg'
import BgImage from '../images/bg/solutions-page-banner.jpg'
import SocialIconsFooter from '../components/social-icons-footer'

const news = () => {
    return (
        <Layout pageTitle="PragICTS | Pay | News">
            <div id="wrapper" className="single-page-wrap">
                <div className="content">
                    <div className="single-page-decor"></div>
                    <div className="single-page-fixed-row">
                        <div className="scroll-down-wrap">
                            <div className="mousey">
                                <div className="scroller"></div>
                            </div>
                            <span>Scroll Down</span>
                        </div>
                        <Link to="/" className="single-page-fixed-row-link"><i className="fal fa-arrow-left"></i> <span>Back to home</span></Link>
                    </div>

                    <section className="parallax-section dark-bg sec-half parallax-sec-half-right" data-scrollax-parent="true">
                        <div className="bg par-elem"  data-bg={BgImage} data-scrollax="properties: { translateY: '30%' }"></div>
                        <div className="overlay"></div>
                        <div className="pattern-bg"></div>
                        <div className="container">
                            <div className="section-title">
                            <h2>News</h2>
                   
                                <div className="horizonral-subtitle"><span>News</span></div>
                            </div>
            
                        </div>
                    </section>
                    <section data-scrollax-parent="true" id="solutions">
            <div className="section-subtitle" data-scrollax="properties: { translateY: '-250px' }" >NEWS <span>//</span></div>
            <div className="container">
               
                <div className="custom-inner-holder  mar-bottom">
                    <div className="custom-inner">
                        <div className="row">
                           
                            <div className="col-md-8">
                            <div class="post fl-wrap fw-post"><h2><a href="blog-single.html"><span>News</span></a></h2></div>
                                <div className="custom-inner-content fl-wrap">
                                    <h4>Authenticities Sri Lanka commissions the PragICTS Pay Platform | 21th November 2019</h4>
                                    <p>Authenticities Sir Lanka, one of the top tier Destination Management Companies of Sri Lanka commissions the PragICTS Pay Platform to enable their clients across the globe the convenience to pay online.</p>
                                    <a href="https://www.authenticitiessrilanka.com/" className="cus-inner-head-link color-bg">Visit </a>
                                </div>
                            </div>
                          
                        </div>
                    </div>
                   
                   
                  

                </div>
            </div>
            <div className="sec-lines"></div>
        </section>
                 

                    <SocialIconsFooter />
                </div>
                <Footer />
            </div>
        </Layout>
    )
}

export default news
